
































































import {Component, Prop, Vue} from 'vue-property-decorator';
import _ from 'lodash';
import {State} from 'vuex-class';

@Component({
  name: 'Profiles'
})
export default class Profiles extends Vue {
  @State private cms: any;
  @Prop() private items: any;
  @Prop() private value: any;

  get options() {
    return _.map(this.items, (item) => item.group.name);
  }

  get selectedOption() {
    return _.get(this.value, 'group.name');
  }

  private isActive(option) {
    return option === _.get(this.value, 'group.name') ? 'main' : 'secondary';
  }

  private onSelectedOption(e) {
    const option = _.find(this.items, (item) => item.group.name.toUpperCase() === (_.get(e, 'target.value', '').toUpperCase() || _.get(e, 'target.innerText', '').toUpperCase()));
    if (!option) {
      return;
    }
    const sameClick = _.get(option, 'group.label') === _.get(this.value, 'group.label');
    this.$emit('input', sameClick ? null : option);
  }
}
