












































import {Component, Vue} from 'vue-property-decorator';
import {Action, Getter, State} from 'vuex-class';
import _ from 'lodash';

import Footer from '@/components/layouts/Footer.vue';
import AppBar from '@/components/layouts/AppBar.vue';
import config from '@/config';
import EventBus from '@/common/EventBus';
import Util from '@/utils/Util';
import HeroSection from '@/components/layouts/HeroSection.vue';


@Component({
  name: 'ErrorPage',
  components: {
    Footer,
    HeroSection
  }
})

export default class Error extends Vue {
  @State private cms: any;
  @State private app: any;
  @Getter('cms/getMessageByCode') private getMessageByCode: any;
  @Action('app/setProposalNumber') private setProposalNumber: any;
  @Action('app/setPolicyIds') private setPolicyIds: any;

  private appBar: AppBar | null = null;

  private created() {
    let parent = this.$parent;
    while (!this.appBar && parent) {
      this.appBar = _.get(parent, '$refs.appBar', null);
      parent = parent.$parent;
    }
    EventBus.$emit('close-loading-dialog');
    EventBus.$emit('cleanup-journey');
    Util.initLivechat(this.app.config.livechat);
    this.setProposalNumber({});
    this.setPolicyIds([]);

    Util.gaLogPageView(this, `/error+${sessionStorage.subSegment ? sessionStorage.subSegment : sessionStorage.targetSegment}`);
  }

  get hero() {
    return '/coverhub-portal/images/error-oops-image.jpg';
  }

  get contactFormEnabled() {
    return _.get(this.app.config, 'contactForm', false);
  }

  get heroTitleColor() {
    return _.get(this.$colors, 'heroTitleCustom.error', this.$colors.heroTitle);
  }

  private contactUs() {
    if (this.appBar) {
      this.appBar.openContactForm();
    }
  }

  private home() {
    Util.goHome(this.$router, this.app);
  }
}
