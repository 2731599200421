














































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter, State} from 'vuex-class';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import _ from 'lodash';

import Footer from '@/components/layouts/Footer.vue';
import RedirectSection from '@/components/layouts/RedirectSection.vue';

import EventBus from '@/common/EventBus';
import HeroSection from '@/components/layouts/HeroSection.vue';

@Component({
  name: 'DownloadPage',
  components: {
    Footer,
    RedirectSection,
    HeroSection
  }
})

export default class DownloadForms extends Vue {
  @State private cms: any;
  @State private app: any;
  @Getter('cms/getMessageByCode') private getMessageByCode: any;
  @Getter('cms/getProductContentByProduct') private getProductContentByProduct: any;
  @Getter('cms/getReducedProducts') private getReducedProducts: any;
  @Action('cms/loadProducts') private loadCMSProducts: any;

  private content: any = [];
  private help: any = {
      address: {
        icon: '/coverhub-portal/images/location.svg',
        data: ''
      },
      fax: {
        icon: '/coverhub-portal/images/fax.svg',
        data: ''
      },
      phone: {
        icon: 'mdi-phone',
        data: ''
      },
      email: {
        icon: 'mdi-email',
        data: ''
      }
    };
  private reducedProducts: any = {};

  @Watch('app.language')
  private onLanguageChanged() {
    this.loadCMSProducts().then(() => {
      this.init();
    });
  }

  private onNumberClick(text) {
    let phoneNumber =  '';
    try {
      phoneNumber = parsePhoneNumberFromString(text)!.format('RFC3966') || '';
    } catch (e) {
      phoneNumber = '';
    }
    if (phoneNumber) {
      window.open(phoneNumber);
    }
  }

  private styleHtml(html) {
    return html.replace(/\<p/g, `<p style="color: ${this.$colors.fontsSecondary};"`)
      .replace(/\<b/g, `<b class="html-title" style="color: ${this.$colors.fontsPrimary};"`);
  }

  private isEmptyHTML(html) {
    const regex = /(<([^>]+)>)/ig;
    const result = html.replace(regex, '');
    return result ? true : false;
  }

  private goToCatalog() {
    const query: any = Object.assign(
      this.$route.query,
      {language: this.app.language, profile: this.app.profile, areaOfService: this.app.areaOfService}
    );
    this.$router.push({ name: 'landing', query});
  }

  private created() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.init();
  }

  get hero() {
    return '/coverhub-portal/images/download-forms-hero.jpg';
  }

  private getValue(data) {
    const element = document.createElement('div');
    element.innerHTML = data;
    if (element) {
      const result = element.getElementsByTagName('b')[0];
      return result ? result.textContent : false;
    }
    return false;
  }

  private init() {
    const {downloadAddress, helpPhone, downloadFax, helpEmail} = this.app;
    const hp = this.getValue(helpPhone);
    const he = this.getValue(helpEmail);
    const helpPhoneValue = hp;
    const helpEmailValue = he;
    this.help = {
      address: {
        icon: '/coverhub-portal/images/location.svg',
        data: downloadAddress
      },
      fax: {
        icon: '/coverhub-portal/images/fax.svg',
        data: downloadFax
      },
      phone: {
        icon: 'mdi-phone',
        data: helpPhoneValue
      },
      email: {
        icon: 'mdi-email',
        data: helpEmailValue
      }
    };
    this.reducedProducts = this.getReducedProducts();
  }

  private onInfoBtnClick(product: any) {
    EventBus.$emit('open-product-detail', product.content);
    this.content = product.content;
  }

  private openEmail(email: string) {
    window.location.href = `mailto:${email}`;
  }
}
