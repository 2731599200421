














import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import _ from 'lodash';

import Footer from '@/components/layouts/Footer.vue';
import config from '@/config';
import EventBus from '@/common/EventBus';
import Util from '@/utils/Util';
import PolicyService from '@/services/PolicyService';

@Component({
	name: 'PaymentProviderRedirect',
	components: {
		Footer,
	},
})
export default class PaymentProviderRedirect extends Vue {
	private redirect: boolean = false;
	private invoiceId: string = '';
	private sessionId: string = '';
	private paymentProvider: string = '';

	private mounted() {
		this.redirect = (this.$route.query.redirect as string) === 'true';
		this.invoiceId = this.$route.query.invoiceId as string;
		this.sessionId = this.$route.query.sessionId as string;
		this.paymentProvider = this.$route.query.paymentProvider as string;

		if (this.redirect) {
			this.settleInvoice(this.invoiceId);
		}
	}

	private async settleInvoice(invoiceId) {
		const paymentResponse = await PolicyService.namedPostPolicyInvoiceSettle(
			invoiceId,
			this.paymentProvider,
			this.sessionId,
		);

		setTimeout(() => {
			this.checkPayment(invoiceId, 60000, this.paymentProvider);
		}, 500);
	}

	private async checkPayment(
		invoiceId: string,
		remainingTime: number,
		paymentProvider: string,
	) {
		const settlement = await PolicyService.getSettlementStatus(invoiceId);
		const settlementStatus = settlement.data.data.status;

		if (settlementStatus === 'IN_PROGRESS') {
			if (remainingTime <= 0) {
				this.$router.push(
					'/error?error=' + encodeURIComponent('Invoice payment timed out.'),
				);
			}
			setTimeout(() => {
				this.checkPayment(invoiceId, remainingTime - 500, paymentProvider);
			}, 500);
		} else {
			if (settlementStatus === 'FAILED') {
				this.$router.push(
					'/error?error=' + encodeURIComponent('Payment failed.'),
				);
			} else {
				const query: any = Object.assign(this.$route.query, {
					id: invoiceId,
					redirectFromPaymentProvider: paymentProvider,
				});
				this.$router.push({
					name: 'confirmation',
					query,
				});
			}
		}
	}
}
