


















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'RedirectSection'
})
export default class RedirectSection extends Vue {
  @Prop() private onClick!: any;
  @Prop() private buttonText!: string;
  @Prop() private title!: string;
}
