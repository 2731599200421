























































import {Component, Vue} from 'vue-property-decorator';
import config from '@/config';
import HeroSection from '@/components/layouts/HeroSection.vue';
import EventBus from '@/common/EventBus';
import Footer from '@/components/layouts/Footer.vue';
import AppBar from '@/components/layouts/AppBar.vue';

@Component({
  name: 'GenericError',
  components: {
    HeroSection,
    Footer,
    AppBar
  }
})

export default class GenericError extends Vue {

  private notFound: boolean = true;

  get helpMessage() {
    return config.texts.helpMessage;
  }

  get copyrightMessage() {
    return config.texts.copyrightMessage;
  }

  get aboutUsLink() {
    return (config.urls as any).aboutUs;
  }

  get transparencyLink() {
    return (config.urls as any).transparency;
  }

  get disclosureLink() {
    return (config.urls as any).disclosure;
  }

  get contactUsLink() {
    return (config.urls as any).contact;
  }

  get termsLink() {
    return (config.urls as any).terms;
  }

  get importantNoticesLink() {
    return (config.urls as any).importantNotices;
  }

  get privacyLink() {
    return (config.urls as any).privacy;
  }

  get fsgLink() {
    return (config.urls as any).fsg;
  }

  get cookiesLink() {
    return (config.urls as any).cookies;
  }

  get whistleblowingLink() {
    return (config.urls as any).whistleblowing;
  }

  get icon() {
    return '/coverhub-portal/images/' + (this.notFound ? 'howdenau-question.svg' : 'howdenau-exclamation.svg');
  }

  get hero() {
    return '/coverhub-portal/images/error-oops-image.jpg';
  }

  get logo() {
    return '/coverhub-portal/images/howdenau-logo.png';
  }

  private created() {
    this.notFound = this.$route.path !== '/error';
  }

  private mounted() {
    EventBus.$emit('close-loading-dialog');
  }

  private home() {
    window.location.href = (config.urls as any).home;
  }
}
