
























































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {State, Getter, Action} from 'vuex-class';
import {Product, Proposal} from '@/models';
import Footer from '@/components/layouts/Footer.vue';
import Survey from '@/components/layouts/Survey.vue';
import RedirectSection from '@/components/layouts/RedirectSection.vue';
import OtherProduct from './OtherProduct.vue';
import FactTooltip from '@/components/common/form/fact/FactTooltip.vue';
import _ from 'lodash';
import Util from '@/utils/Util';
import {default as GAUtil, IODTransactionData} from '@/utils/GAUtil';
import Membership from '@/components/layouts/Membership.vue';
import EventBus from '@/common/EventBus';
import QuoteProduct from '@/models/QuoteProduct';

@Component({
  name: 'Confirmation',
  components: {
    Footer,
    OtherProduct,
    Membership,
    FactTooltip,
    RedirectSection,
    Survey
  },
})
export default class Confirmation extends Vue {
  @State private app: any;
  @State private cms: any;
  @State private proposal!: Proposal;
  @Getter('proposal/getSelectedProducts') private getSelectedProducts: any;
  @Getter('proposal/invalidUnderWritingResult') private invalidUnderWritingResult: any;
  @Getter('proposal/getInvoicesPriceToPay') private getInvoicesPriceToPay: any;
  @Getter('proposal/getInvoicesTotalTax') private getInvoicesTotalTax: any;
  @Getter('quotation/isPriceValidByProductId') private isPriceValidByProductId!: (productID: string, isContinuation: boolean) => boolean;
  @Getter('quotation/isValidShiftResultByProductID') private isValidShiftResultByProductID!: (productID: string) => boolean;
  @Getter('quotation/getConfirmedTotalQuotedPriceToPay') private getConfirmedTotalQuotedPriceToPay!: (isContinuation: boolean) => number;
  @Getter('quotation/getConfirmedTotalQuotedTax') private getConfirmedTotalQuotedTax!: (isContinuation: boolean) => number;
  @Getter('quotation/getProductPriceToPay') private getProductPriceToPay!: (productID: string, priceType: string, isAfterUW: boolean) => number;
  @Getter('quotation/getQuotedPrices') private getQuotedPrices!: () => any;
  @Getter('quotation/getTotalTaxPercentage') private getTotalTaxPercentage!: () => number;
  @Getter('quotation/getAllCustomProductTrees') private getAllCustomProductTrees!: () => any;
  @Action('proposal/reset') private proposalReset: any;
  @Action('app/setActiveStep') private setActiveStep: any;
  @Action('app/setValue') private setValue: any;
  @Action('app/setLoadingText') private setLoadingText: any;
  @Action('quotation/reset') private resetQuotation: any;
  @Getter('quotation/getFactValueByFactID') private getFactValueByFactID!: (factID: string) => string;
  @Getter('quotation/getSortedQuotationProduct') private getSortedQuotationProduct!: () => QuoteProduct[];

  private products: any[] = [];
  private paymentSkipped: boolean = false;
  private noPayment: boolean = false;
  private hasPurchased: boolean = false;
  private hasUnpurchased: boolean = false;
  private phone: string = '';
  private email: string = '';
  private province: any = '';
  private content: any = [];
  private priceValidityArray: any = [];
  // private proposalTax: any = '';
  private proposalRefundAmt: any = '';
  private proposalRefundTaxAmt: any = '';
  private proposalRwoTax: any = '';
  private priceToPay: any = '';
  private totalTax: any = '';

  private created() {
    this.setLoadingText(null);

    this.products = this.getSelectedProducts();
    for (const p of this.products) {
      this.priceValidityArray[p.id] = (this.$route.query.type ||
              this.$route.query.redirectFromPaymentProvider ||
              this.proposal.isEndorsment ||
              this.isPriceValidByProductId(p.id, this.app.isContinuation)) &&
          this.isValidShiftResultByProductID(p.id);
    }
    this.proposalRefundAmt = Number.isNaN(this.proposal.refundAmt) ? 0 : this.proposal.refundAmt;
    this.proposalRefundTaxAmt = Number.isNaN(this.proposal.refundTaxAmt) ? 0 : this.proposal.refundTaxAmt;
    this.proposalRwoTax = this.getRwoTax();
    this.paymentSkipped = this.proposal.paymentSkipped;
    this.noPayment = this.proposal.noPayment;
    this.hasPurchased = this.hasPurchasedProduct();
    this.hasUnpurchased = this.hasUnpurchasedProduct();
    const contactInfo = this.proposal.contactInfo;
    this.phone = contactInfo.phone;
    this.email = contactInfo.email;
    this.province = this.getProductLevelFactValueById(Util.APPLICANT_PROVINCE_ID);
    this.priceToPay = (this.app.isContinuation || this.proposal.isEndorsment) ? this.getInvoicesPriceToPay() : this.getConfirmedTotalQuotedPriceToPay(this.app.isContinuation);
    this.totalTax = (this.app.isContinuation || this.proposal.isEndorsment) ? this.getInvoicesTotalTax() : this.getConfirmedTotalQuotedTax(this.app.isContinuation);
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
    EventBus.$emit('cleanup-journey');

    Util.gaLogPageView(this, `/confirmation+${sessionStorage.subSegment ? sessionStorage.subSegment : sessionStorage.targetSegment}`);
  }

  private mounted() {
    this.setValue({code: 'dirty', value: false});
    this.setActiveStep(-1);
    this.$global.enterAnimation();
    this.$nextTick(() => {
      EventBus.$emit('stepper-idle');
    });
    window.onbeforeunload = () => {
      this.proposalReset();
      this.resetQuotation();
      this.setValue({code: 'isSavedProposal', value: false});
    };

    if (this.$route.query?.completed) {
      this.ODLog();
    }
  }

  private ODLog() {
    const shiftProducts = this.getSortedQuotationProduct();
    const toLogProducts: any[] = [];
    const transactionData: IODTransactionData = {
      id: this.proposal.proposalId || this.proposal.invoiceId || this.app.proposalId || this.app.bundleId,
      affiliation: sessionStorage.subSegment ? sessionStorage.subSegment : sessionStorage.targetSegment,
      revenue: `${this.getPriceAfterRefunds()}`,
      tax: `${this.totalTax}`
    };
    for (const p of this.products) {
      const shiftProduct = _.find(shiftProducts, (sp) => sp.id === p.id);
      if (shiftProduct) {
        shiftProduct.policyNumber = p.policyNumber;
        toLogProducts.push(shiftProduct);
      }
    }
    GAUtil.ODLogConfirmation(transactionData, toLogProducts, this.app.isContinuation);
  }

  private beforeDestroy() {
    this.$global.leaveAnimation();
    window.onbeforeunload = () => {/*reset unload func*/};
  }

  get skipped() {
    return this.cms.theme.confirmationSkipped;
  }

  get covered() {
    return this.cms.theme.confirmation;
  }

  get adviser() {
    return this.cms.theme.confirmationAdviser;
  }

  get refundWOTax() {
    return this.proposalRwoTax;
  }

  get confirmationWording() {
    return `confirmation.policy.${_.isEmpty(this.$route.query.type) ? 'email' : this.$route.query.type}`;
  }

  get confirmationWordingPostfix() {
    return _.isEmpty(this.$route.query.type) ? null : `confirmation.policy.${this.$route.query.type}_postfix`;
  }

  private getProductLevelFactValueById(id: string) {
    return this.getFactValueByFactID(id);
  }

  private isPriceValid(product: Product) {
    return  this.priceValidityArray[product.id];
  }

  private getCurrentPrice(product: Product) {
    return this.getProductPriceToPay(product.id, 'totalPrice', true);
  }

  private getTaxPercentage() {
    return this.getTotalTaxPercentage();
  }

  private hasPurchasedProduct() {
    for (const p of this.products) {
      if (this.isPriceValid(p)) {
        return true;
      }
    }
    return false;
  }

  private hasUnpurchasedProduct() {
    for (const p of this.products) {
      if (!this.isPriceValid(p)) {
        return true;
      }
    }
    return false;
  }

  // this method need to be move to a shared place.
  get getAfterReviewInterpolationObject() {
    const proposalFacts: any = {};
    proposalFacts.applicantFullName = `${this.proposal.contactInfo.givenName} ${this.proposal.contactInfo.familyName}`;

    try {
      // only gets facts from the first product

      const productTree = this.getAllCustomProductTrees();
      if (productTree && productTree.length > 0 && productTree[0].allFacts) {
        const firstProduct = productTree[0];
        firstProduct.allFacts.forEach((fact) => {
          proposalFacts[fact.id.replaceAll('.', '_').replaceAll(':', '_')] = fact.currentValue;
        });
        if (firstProduct.components) {
          firstProduct.components.forEach((component) => {
            if (component.allFacts) {
              component.allFacts.forEach((fact) => {
                proposalFacts[fact.id.replaceAll('.', '_').replaceAll(':', '_')] = fact.currentValue;
              });
            }
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
    return proposalFacts;
  }

  private getCoverageDates(product: any) {
    const quoted = _.find(this.getQuotedPrices(), (q) => q.productDetail.id === product.id);
    return quoted?.coverageDateRange || '';
  }

  private onInfoBtnClick(content: any) {
    this.content = content;
    EventBus.$emit('open-product-detail', content);
  }

  private getPriceToPayAfterRefunds() {
    return this.priceToPay + this.proposalRwoTax;
  }

  private getTaxAfterRefunds() {
    return this.totalTax + this.proposalRefundTaxAmt;
  }

  private getPriceAfterRefunds() {
    return this.priceToPay + this.totalTax + this.proposalRefundAmt;
  }

  private getRwoTax() {
    if (Number.isNaN(this.proposal.refundAmt) || Number.isNaN(this.proposal.refundTaxAmt)) {
      return 0;
    }
    return this.proposal.refundAmt - this.proposal.refundTaxAmt;
  }
  private goHomeTitle() {
    const to = _.get(this.app, 'homeUrl') || '';
    if (to.match(/^\w+$/)) {
      return this.$t('confirmation.back.' + to);
    }
    return this.$t('confirmation.backUrl');
  }

  private goHomeLabel() {
    const to = _.get(this.app, 'homeUrl') || '';
    if (to.match(/^\w+$/)) {
      return this.$t('button.confirmation.' + to);
    }
    return this.$t('button.home');
  }

  private goHome() {
    EventBus.$emit('cleanup-journey', () => {
      EventBus.$emit('init-app', () => {
        this.proposalReset();
        this.resetQuotation();
        this.setValue({code: 'isSavedProposal', value: false});
        Util.goHome(this.$router, this.app);
      });
    });
  }

  private hasTaxInfo() {
    return !!this.$t('product.summary.tax.comment');
  }
}
