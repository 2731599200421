


















































































































































  import {FactService} from '@/services/FactService';
  import {Component, Vue} from 'vue-property-decorator';
  import {State, Getter, Action} from 'vuex-class';
  import _ from 'lodash';

  import DefaultProductSelectionList from '@/components/products/productSelectionList/Default.vue';
  import Footer from '@/components/layouts/Footer.vue';
  import RedirectSection from '@/components/layouts/RedirectSection.vue';
  import HeroSection from '@/components/layouts/HeroSection.vue';
  import Profiles from '@/components/layouts/Profiles.vue';
  import Util from '@/utils/Util';
  import EventBus from '@/common/EventBus';
  import {IdleService} from '@/services/IdleService';
  import {PardotService} from '@/services/PardotService';
  import ProductService from '@/services/ProductService';
  import ProductEngineUtils from '@/utils/ProductEngineUtils';

  const CATALOGUE_LAYOUT = 'ProductSelectionList';

  @Component({
    name: 'LandingCatalogue',
    components: {
      DefaultProductSelectionList,
      Footer,
      Profiles,
      RedirectSection,
      HeroSection
    }
  })

  export default class TargetSegmentSelection extends Vue {
    @State private app: any;
    @State private cms: any;
    @State private proposal: any;
    @State private products: any;

    @Getter('products/getProductByCmsProduct') private getProductByCmsProduct: any;
    @Getter('cms/getReducedProducts') private getReducedProducts: any;
    @Action('cms/loadProductsProfiles') private loadProductsProfiles: any;
    @Action('cms/loadProductsLines') private loadProductsLines: any;
    @Action('proposal/reset') private proposalReset: any;
    @Action('app/setProducts') private setProducts: any;
    @Action('app/setLanguage') private setLanguage: any;
    @Action('app/setActiveStep') private setActiveStep: any;
    @Action('app/setClientId') private setClientId: any;
    @Action('app/resetRenewalResponse') private resetRenewalResponse: any;
    @Action('app/getQuotes') private getQuotes: any;
    @Action('app/setValue') private setValue: any;
    @Action('products/list') private loadShiftProducts: any;
    @Action('quotation/reset') private resetQuotation: any;

    private productLoaded: boolean = false;
    private selectedProducts: any = [];
    private preselectedProducts: any = [];
    private profiles: any = [];
    private profilesAvailable: boolean | null = null;
    private profile: any;
    private line: any;
    private selectedProfile: any = null;
    private content: any = [];
    private footerVisibility = false;
    private applyAfterReload: boolean = false;

    get isRenewal() {
      return this.app.isRenewal;
    }

    get showRenewal() {
      return !this.app.isRenewal && _.get(this.app, 'showRenewLink', false);
    }

    get hasApplicationForm() {
      return !!_.filter(this.getReducedProducts(), (product) => product && product.applicationForm).length;
    }

    get layoutType() {
      return this.cms.layout.catalogueType + CATALOGUE_LAYOUT;
    }

    get secondaryBorder() {
      return '2px solid ' + this.$colors.secondaryButtonBorder;
    }

    private async created() {
      this.setValue({code: 'dirty', value: this.app.isRenewal || this.app.isContinuation});
      this.setActiveStep(-1);
      if (this.app.isRenewal) {
        this.resetRenewalResponse();
        await this.getQuotes(this.app.proposalId).catch((e: any) => console.error(e));
      }
      this.registerEvents();
      this.load();
      this.sendToCRM();
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });

      Util.gaLogPageView(this, `/landing+${sessionStorage.subSegment ? sessionStorage.subSegment : sessionStorage.targetSegment}`);
      this.$nextTick(() => {
        EventBus.$emit('stepper-idle');
      });
    }

    private async mounted() {
      if (!this.app.isRenewal) {
          EventBus.$emit('need-token', async () => {
            await this.loadShiftProducts();
            this.productLoaded = true;
            EventBus.$emit('got-token');
          });
      } else {
        await this.loadShiftProducts();
        const products = await this.productSource;
        if (products?.length) {
          Util.gtmLogEcommerceEvent(this, 'page_load', 'view_item_list', products.map((product) => ({
            item_name: product.fields?.title,
            item_id: product.fields?.id,
            item_category: product.category,
            item_code: product.code,
            index: product?.fields?.index,
            quantity: 1,
            price: product.fields?.startingPremium
          })));
        }
        this.productLoaded = true;
      }
    }

    private goToRenewal() {
      this.$router.push({name: 'renewal-request'});
    }

    private registerEvents() {
      EventBus.$on('onReload', () => {
        this.load();
        if (this.applyAfterReload) {
          this.applyAfterReload = false;
          return this.goTo();
        }
        if (_.isEmpty(this.selectedProfile)) {
          this.profile = this.getProfileDetails(this.app.profile, this.profiles);
          if (!_.isEmpty(this.profile)) {
            this.selectedProfile = this.profile;
          }
        }
      });
    }

    get productSource() {
      if (this.app.isRenewal) {
        this.preselectedProducts = [];
        const renewalIds = _.values(_.get(this.app, 'renewalResponse.renewalIds', {}));
        if (renewalIds.length) {
          this.app.renewalResponse.productCodes = [];
          renewalIds.forEach(async (id) => {
            const shiftProduct = await ProductService.getProduct(id);
            const code = _.get(shiftProduct, 'data.data.code');
            this.app.renewalResponse.productCodes.push(code);
          });
          this.preselectedProducts = this.app.renewalResponse.productCodes;
        }
      } else if (this.profile && this.line) {
        this.preselectedProducts = this.getPreselectedProducts(this.profile, this.line);
      }
      return this.cms.products || [];
    }

    private load() {
      if (!_.get(this, 'app.targetSegment')) {
        return setTimeout(this.load, 100);
      } else {
        if (!this.app.isContinuation && this.proposal) {
          this.proposalReset();
        }
        this.loadProductsProfiles().then((response: any) => {
          this.profiles = response;
          this.profilesAvailable = !!response.length;
          this.profile = this.getProfileDetails(this.app.profile, response);
          this.loadProductsLines().then((resp: any) => {
            this.line = resp && resp.find((r) => r.group.code === this.app.line);
            if (this.profile) {
              this.selectedProfile = this.profile;
              Util.gaLogCatalogueEvent(this, 'Preselected Profile', this.app.profile);
              if (this.line) {
                this.preselectedProducts = this.getPreselectedProducts(this.profile, this.line);
              }
            }
            if (this.line) {
              Util.gaLogCatalogueEvent(this, 'Preselected Line', this.app.line);
            }
          });
        });
      }
    }

    private getProfileDetails(profile: string, data: any) {
      return data && data.find((r) => r.group.name === profile);
    }

    get hero() {
      return _.get(this , 'cms.theme.hero');
    }

    private getPreselectedProducts(profile: any, line: any) {
      const common: any[] = [];
      for (const l of line.products) {
        if (l.match(/^PROD_CODE:/)) {
          for (const p of profile.products) {
            if (l === p) {
              const code = p.replace(/^PROD_CODE:/, '');
              common.push(code);
              break;
            }
          }
        }
      }
      return common;
    }

    get isSelection() {
      return !_.get(this.selectedProducts, 'length', 0);
    }

    private async applyCoverage() {
      if (this.proposal) {
        this.proposalReset();
      }
      if (this.isSelection) {
        this.$dialog.open({
          icon: _.get(this.cms, 'theme.information'),
          text: this.$t('catalogue.applyCoverage.dialog.noSelection'),
          buttons: [
            {
              type: 'main',
              text: this.$t('button.ok')
            }
          ]
        });
        return;
      }
      IdleService.resetTimer();
      if (!this.products.length) {
        if (this.products === undefined && this.products.length === 0) {
          return setTimeout(async () => {
            await this.loadShiftProducts();
            this.applyCoverage();
           }, 1000);
        }
        Util.gtmLogCustomEvent(this, 'click', 'tracked_error', {
          error_type: 'technical-issue',
          error_message:  'Shift: no products' 
        });
        return this.$dialog.open({ type: 'technical-issue', info: 'Shift: no products' });
      }

      const feLangSupported = this.app.journeyLanguages ? this.app.journeyLanguages.includes(this.app.language) : this.app.language !== 'en';
      let beLangSupported = true;
      for (const product of this.selectedProducts) {
        const languages = product.fields?.additionProductDefinition?.languages || ['en'];
        beLangSupported = beLangSupported && languages.includes(this.app.language);
      }
      if (feLangSupported && beLangSupported) {
        this.goTo();
      } else {
        const buttons = [
          {
            text: this.$t('catalogue.applyCoverage.dialog.fallback'),
            onClick: this.onContinue
          },
          {
            type: 'main',
            text: this.$t('catalogue.applyCoverage.dialog.download'),
            onClick: this.onDownload
          }
        ];
        if (!this.hasApplicationForm) {
          buttons.pop();
        }
        this.$dialog.open({
          icon: _.get(this.cms, 'theme.failIcon'),
          text: this.hasApplicationForm ? this.$t('catalogue.applyCoverage.dialog.message') : this.$t('catalogue.applyCoverage.dialog.noFormMessage'),
          buttons
        });
      }
    }

    private goTo() {
      this.setActiveStep(0);
      const productCodes: any = _.map(_.sortBy(this.selectedProducts, 'index'), 'code');
      Util.gaLogCatalogueEvent(this, 'Apply for coverage', productCodes.toString());
      this.setProducts(productCodes);
      // FactService.resetSessions(productCodes);
      const query: any = Object.assign(
        this.$route.query,
        {products: productCodes, language: this.app.language, profile: this.app.profile, areaOfService: this.app.areaOfService}
      );
      this.$router.push({name: 'portal', query});
    }

    private onDownload() {
      this.$dialog.close();
      this.goToDownloadForms();
    }

    private onContinue() {
      this.$dialog.close();
      this.setLanguage('en');
      this.applyAfterReload = true;
      EventBus.$emit('reload-cms', 'en');
    }

    get footerVisbility() {
      return this.footerVisibility;
    }

    private onVisibleChanged(this: any, isVisible: any, entry: any) {
      this.footerVisibility = isVisible;
    }

    private goToDownloadForms() {
      const query: any = Object.assign(
        this.$route.query,
        {language: this.app.language, profile: this.app.profile, areaOfService: this.app.areaOfService}
      );
      this.$router.push({ name: 'download-forms', query});
    }

    private onProductSelected(selectedProducts: any[]) {
      if (selectedProducts?.length) {
        Util.gtmLogEcommerceEvent(this, 'click', 'select_item', selectedProducts.map((product) => ({
            item_name: product.fields?.title,
            item_id: product.fields?.id,
            item_category: product.category,
            item_code: product.code,
            index: product?.fields?.index,
            quantity: 1,
            price: product.fields?.startingPremium
          })));
      }
      this.selectedProducts = selectedProducts;
    }

    private onInfoBtnClick(content: any) {
      Util.gtmLogEcommerceEvent(this, 'click', 'view_item', {
        item_name: content.title,
        item_id: content?.id,
        item_category: content.category,
        item_code: content.code,
        index: content?.index,
        quantity: 1,
        price: content.startingPremium

      });
      EventBus.$emit('open-product-detail', content);
      this.content = content;
		}

    private async sendToCRM() {
      if (this.app.isRenewal && this.app.renewalResponse && this.app.originUrl) {
        _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_RENEWAL_ACCESSED, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)();
      }
    }
  }
