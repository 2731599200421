

import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {State, Getter} from 'vuex-class';
import _ from 'lodash';
import Enums from '@/utils/Enums';
import Util from '@/utils/Util';
import EventBus from '@/common/EventBus';
import DefaultProductCard from '@/components/common/product/ProductCard/Default.vue';
import ProductImageProductCard from '@/components/common/product/ProductCard/ProductImage.vue';
import ProductSideImageProductCard from '@/components/common/product/ProductCard/ProductSideImage.vue';
import DetailProductCard from '@/components/common/product/ProductCard/Detail.vue';
import GreyOutDetailProductCard from '@/components/common/product/ProductCard/GreyOutDetail.vue';
import GAUtil from '@/utils/GAUtil';

const PRODUCTCARD_TYPE = 'ProductCard';

@Component({
  name: 'ProductSelectionList',
  components: {
    DefaultProductCard,
    ProductImageProductCard,
    ProductSideImageProductCard,
    DetailProductCard,
    GreyOutDetailProductCard
  }
})
export default class Mixin extends Vue {
  @Prop() protected productSource: any;
  @Prop() protected selectedProducts: any;
  @Prop() protected selectedProfile: any;
  @Prop() protected preselectedProducts: any;
  @Prop() protected isRenewal!: boolean;
  @Prop({ default: true }) protected hideInactiveProducts!: boolean;
  @State protected app: any;
  @State protected cms: any;
  @Getter('cms/getProductContentByProduct') protected getProductContentByProduct: any;
  protected filteredProducts: any = [];
  protected otherProducts: any = [];
  protected selected: any = [];
  protected productDisplayState: any = [];
  protected otherProductDisplayState: any = [];

  protected cardFormatted = false;
  @State private products: any;

  protected getCardType() {
    return this.cms.layout.cardType + PRODUCTCARD_TYPE;
  }

  protected formatCards() {
    const customFormat = ['ProductSideImage'];
    const regx = new RegExp('\\b' + 'row-number-' + '(.*)?\\b', 'g');
    this.$nextTick(() => {

      let row = 0;
      let lastOffset = -1;

      const products = [...document.querySelectorAll('.product')];

      products.forEach((product) => {
        product.className = product.className.replace(regx, '');
      });

      products.forEach((product, key) => {
        const productName = (product.querySelector('.product-name') as HTMLElement);
        const premium = (product.querySelector('.premium') as HTMLElement);
        const benefit = (product.querySelector('.benefits') as HTMLElement);
        if (productName) {
          productName.style.height = 'auto';
        }
        if (premium) {
          premium.style.height = 'auto';
        }
        if (benefit) {
          benefit.style.height = 'auto';
        }

        if (lastOffset < (product as HTMLElement).offsetTop) {
          Util.getSameHeight([...document.querySelectorAll('.row-number-' + row)]);
          row++;
          lastOffset = (product as HTMLElement).offsetTop;
        }

        product.className = product.className.replace(regx, '');
        product.classList.add('row-number-' + row);

        if (key === products.length - 1) {
          Util.getSameHeight([...document.querySelectorAll('.row-number-' + row)]);
        }

        if (customFormat.includes(this.cms.layout.cardType)) {
          const img = (product.querySelector('.img') as HTMLElement);
          const details = (product.querySelector('.details') as HTMLElement);
          if (img && details) {
            img.style.height = (details.offsetHeight) + 'px';
          }
        }
      });
      this.cardFormatted = true;
    });
  }

  protected filterProductsByRoleOrRenewal() {
    this.filteredProducts = [];
    this.otherProducts = [];
    _.forEach(this.productSource, (product: any) => {
      if (!product.code) {
        const key = Enums.CMS.PRODUCTS.METADATA.PROD_CODE;
        const productCode = _.get(product, 'fields.metadata', []).find((f) => f.indexOf(`${key}:`) === 0) || '';
        product.code = productCode.replace(/.*:/, '');
      }
      const indexedProd = {index: _.get(product, 'fields.index', 0), ...product};
      if (!_.isEmpty(this.selectedProfile)) {
        const isAccessible = this.selectedProfile.products.findIndex((p) => p.includes(':' + product.code)) !== -1;
        if (isAccessible) {
          this.filteredProducts.push(indexedProd);
        } else {
          this.otherProducts.push(indexedProd);
        }
      } else {
        if (this.isRenewal && this.preselectedProducts && this.preselectedProducts.some((ps) => ps === indexedProd.code)) {
          this.filteredProducts.push(indexedProd);
        } else {
          this.otherProducts.push(indexedProd);
        }
      }
    });

    this.filteredProducts = _.sortBy(this.filteredProducts, ['index']);
    this.otherProducts = _.sortBy(this.otherProducts, ['index']);
    this.selected = [];
    if (this.preselectedProducts && this.preselectedProducts.length) {
      for (const p of this.preselectedProducts) {
        this.filteredProducts.forEach((fp) => {
          if (fp.code === p) {
            fp.index = 0;
          }
        });
      }
      this.filteredProducts = _.sortBy(this.filteredProducts, ['index']);
      for (const prod of this.preselectedProducts) {
        const pp = this.filteredProducts.find((fp) => fp.code === prod);
        if (pp) {
          this.toggleSelection(pp);
        }
      }
    }

    GAUtil.ODLogProductsImpression('landing', _.map([...this.filteredProducts, ...this.otherProducts], (product) => ({
      code: product.code,
      currentPrice: product?.fields?.startingPremium && product?.fields?.startingPremium.replace(/\D/g, ''),
      product: {
        name: product?.fields?.title || ''
      }
    })));
  }

  protected checkSelected(product: any) {
    return !!_.find(this.selected, {code: product.code});
  }

  protected isRenewalAndNotNewBusiness(product: any) {
    if (this.app.isRenewal) {
      if (this.app.renewalResponse.nonRenewalProductCodes && this.app.renewalResponse.nonRenewalProductCodes.includes(product.code)) {
        return false;
      }
      return true;
    }
    return false;
  }

  protected onInfoBtnClick(content: any) {
   this.$emit('onInfoBtnClick', content);
  }

  protected getSelectable(product) {
    // if the config is set to false then it should be not be available
    if (product.fields?.selectable === false) {
      return false;
    }
    // when there is no product selected, all products are available for selection.
    if (this.selected.length === 0) {
      return true;
    }

    // when the selected product is external pricing, no more product can be selected.
    // since a product is external pricing cannot be bundled with any other products, only need to check the first item.
    const selectedFirstProduct = this.products.find((p: any) => p.code === this.selected[0].code );
    if (selectedFirstProduct.allowExternalPricing) {
      return false;
    }

    const currentProduct = this.products.find((p: any) => p.code === product.code );
   // when the selected product is not external pricing, but the current product is external pricing then current product cannot be selected
    if (currentProduct.allowExternalPricing) {
      return false;
    }

    // when the selected product is not external pricing and existing product doesn't contain external pricing
    return true;
  }

  private isProductActive(productCode: string) {
    if (this.products && this.products.length > 0) {
      const product = this.products.find((p: any) => p.code === productCode);
      return (this.hideInactiveProducts && product && product.active) || !this.hideInactiveProducts;
    }
    return true;
  }

  private toggleSelection(product: any) {
    const isSelected: boolean = this.checkSelected(product);
    if (isSelected) {
      this.selected = _.reject(this.selected, {code: product.code});
    } else {
      this.selected.push(product);
      GAUtil.ODLogAddProduct({
        code: product.code,
        currentPrice: product?.startingPremium ? product?.startingPremium.replace(/\D/g, '') : '0',
        product: {
          name: product?.fields?.title || ''
        }
      }, product.index, 'landing');
    }
  }

  private created() {
    EventBus.$on('activateCardStatus', (payload, err) => {
      if (payload.isFiltered) {
        _.forEach(this.productDisplayState, (state: any, index: any) => {
          state[payload.type] = index === payload.index;
        });
      } else {
        _.forEach(this.otherProductDisplayState, (state: any, index: any) => {
          state[payload.type] = index === payload.index;
        });
      }
    });
  }

  private mounted() {
    this.filterProductsByRoleOrRenewal();

    this.formatCards();
    setTimeout(() => {
      this.formatCards();
    }, 500);
    if (!this.$isIE && (document as any).fonts) {
      (document as any).fonts.onloadingdone =  () => {
        setTimeout(() => {
          this.formatCards();
        }, 500);
      };
    }
  }

  @Watch('$vuetify.breakpoint.width')
  private onWidthChange() {
    this.formatCards();
  }

  @Watch('selectedProfile')
  private onSelectedProfileChanged() {
    Util.gaLogCatalogueEvent(this, 'Select Profile', this.selectedProfile);
    this.filterProductsByRoleOrRenewal();
  }

  @Watch('preselectedProducts')
  private onPreselectedChanged() {
    this.filterProductsByRoleOrRenewal();
    this.formatCards();
  }

  @Watch('productSource')
  private onProductSourceChanged() {
    this.filterProductsByRoleOrRenewal();
    this.formatCards();
  }

  @Watch('selected')
  private selectedChanged() {
    this.$emit('onChange', this.selected);
  }

  @Watch('filteredProducts')
  private productsDisplayChanged() {
    this.productDisplayState = [];
    _.forEach(this.filteredProducts, (product: any, index: any) => {
      this.productDisplayState.push({
        details: false,
        index
      });
    });
  }

  @Watch('otherProducts')
  private otherProductsDisplayChanged() {
    this.otherProductDisplayState = [];
    _.forEach(this.otherProducts, (product: any, index: any) => {
      this.otherProductDisplayState.push({
        details: false,
        index
      });
    });
  }

}
