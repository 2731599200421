





































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {State, Getter, Action} from 'vuex-class';
import _ from 'lodash';
import ProductEngineUtils from '@/utils/ProductEngineUtils';

import {SalesforceService} from '@/services/SalesforceService';
import Proposal from '@/models/proposal';
import Util from '@/utils/Util';
import EventBus from '@/common/EventBus';
import DefaultProductSelectionList from '@/components/products/productSelectionList/Default.vue';
import WorkflowService from '@/services/WorkflowService';

const CATALOGUE_LAYOUT = 'ProductSelectionList';

@Component({
  name: 'OtherProducts',
  components: {
    DefaultProductSelectionList
  },
})
export default class OtherProducts extends Vue {
  @Prop() private product: any;
  @State private app: any;
  @State private cms: any;
  @State private proposal!: Proposal;
  @Getter('cms/getProductContentByProduct') private getProductContentByProduct: any;
  @Action('app/setPardotAvailability') private setPardotAvailability: any;
  @Action('cms/loadOtherProducts') private loadOtherProductsContent: any;
  @Getter('quotation/getFactValueByFactID') private getFactValueByFactID!: (factID: string) => string;

  private products: any = [];
  private selected: any = [];

  get layoutType() {
    return this.cms.layout.catalogueType + CATALOGUE_LAYOUT;
  }

  private toggleSelection(selected: any) {
    this.selected = selected;
  }

  private checkSelected(product: any) {
    return !!_.find(this.selected, {code: product.code});
  }

  private callAdvisor() {
    EventBus.$emit('open-loading-dialog');
    this.sendTask().then(() => {
      setTimeout(() => {
        this.$dialog.open({
          icon: _.get(this.cms, 'theme.successIcon'),
          text: this.$t('confirmation.otherProduct.confirmDialogMessage'),
          buttons: [{ text: this.$t('button.closeDialog') }]
        });
        EventBus.$emit('close-loading-dialog');
      }, 2000);
      this.selected = [];
    });
  }

  private async sendTask() {
    const productsInSTring = this.selected.map((product: any) => product.title).join(', ');
    const contactInfo = this.proposal.contactInfo;
    let context: any = {
      title: 'A user is interested in an offline product',
      type: 'Coverhub request',
      source: window.location.href,
      name: `${contactInfo.givenName} ${contactInfo.familyName}`,
      phone: contactInfo.phone,
      email: contactInfo.email,
      message: `Product: ${productsInSTring}`,
    };

    const workflowName = this.proposal?.proposalId ? 'create-offline-product-interest-form' : 'create-contact-form-task';

    if (this.proposal?.proposalId) {
      context = {
        ...context,
        proposalId: this.proposal?.proposalId
      };
    }
    return WorkflowService.runWorkflowSync(workflowName, context, ['task']);
  }

  private created() {
    this.loadProducts();
  }

  private async loadProducts() {
    await this.loadOtherProductsContent();

    const otherProducts = _.filter(this.cms.otherProducts, (prod) => {
      return !prod.fields.hide;
    });
    this.products = _.sortBy(
        _.map(otherProducts, (product: any) =>
            this.getProductContentByProduct(product),
        ),
        ['index'],
    );
  }

  private onInfoBtnClick(product: any) {
    this.$emit('onInfoBtnClick', product);
  }
}
