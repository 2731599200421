

































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Getter, State} from 'vuex-class';
import _ from 'lodash';

@Component({
  name: 'HeroSection'
})

export default class HeroSection extends Vue {
  @State private cms: any;
  @Getter('cms/getMessageByCode') private getMessageByCode: any;
  @Prop() private heading: any;
  @Prop() private headingColor: any;
  @Prop() private midheading: any;
  @Prop() private midheadingColor: any;
  @Prop() private subheading: any;
  @Prop() private subheadingColor: any;
  @Prop() private imageSrc: any;
  @Prop() private sameSize: any;
  @Prop({default: (val: any) => {/* placeholder callback */}}) private applyCoverage!: any;

  get isLanding() {
    return _.get(this.$route, 'name', '') === 'landing';
  }

  get byCode() {
    return _.has(this.$attrs, 'byCode');
  }

  get singleProductMultiBenefitLayout() {
    if (this.cms.layout) {
      return this.cms.layout.landingCatalogueType === 'Coverages';
    }
    return false;
  }

}
