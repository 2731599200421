import { render, staticRenderFns } from "./GenericError.vue?vue&type=template&id=546865f6&scoped=true&"
import script from "./GenericError.vue?vue&type=script&lang=ts&"
export * from "./GenericError.vue?vue&type=script&lang=ts&"
import style0 from "./GenericError.vue?vue&type=style&index=0&id=546865f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546865f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VIcon,VImg,VLayout})
