































import {Component, Vue, Watch} from 'vue-property-decorator';
import {State, Getter} from 'vuex-class';
import _ from 'lodash';
import Util from '@/utils/Util';

@Component({ name: 'Survey' })
export default class Survey extends Vue {
  @State private auth: any;
  @State private app: any;
  @Getter('cms/getSurvey') private getSurvey: any;

  private survey: any = [];
  private answered = '';
  private showSurvey = false;
  private step = 0;
  private surveyDisplayed = false;
  private cookieName = '';
  private timeout = 4000;

  @Watch('showSurvey')
  private toggleScroll() {
    this.$nextTick(() => { (this.$refs.surveyDialog as any).showScroll(); });
  }

  get questions() {
    return this.surveyDisplayed ? _.filter(this.survey, (q) => !q.conditional) : this.survey;
  }

  private created() {
    this.cookieName = 'survey-' + this.auth.cms.space + '-' + this.app.targetSegment + '-displayed';
    this.init();
  }

  private async init() {
    this.surveyDisplayed = this.$cookies.isKey(this.cookieName);
    this.survey = await this.getSurvey(this.app.targetSegment, this.app.language);
    setTimeout(() => {
      this.showSurvey = !!this.survey;
    }, this.timeout);
  }

  private next(question, answer) {
    if (this.step + 1 === this.questions.length) {
      return this.close();
    }
    Util.gaLogSurveyEvent(this, question, answer);
    this.answered = answer;
    setTimeout(() => this.step++, 300);
  }

  private close() {
    this.showSurvey = false;
    this.$cookies.set(this.cookieName, 'true', new Date(2038, 1, 19));
    setTimeout(() => this.survey = undefined, 300);

  }
}
