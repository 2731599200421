

































































import {Mixins} from 'vue-property-decorator';
import Mixin from './Mixin.vue';

export default class Default extends Mixins(Mixin) {
}
